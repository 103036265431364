import React from 'react';
import HeroSlider from '../components/sliders/HeroSlider';
import FeaturedSlider from '../components/sliders/FeaturedSlider';
import SectionsHead from '../components/common/SectionsHead';
import TopProducts from '../components/product/TopProducts';
import Services from '../components/common/Services';

import Map, {Marker, Popup} from 'react-map-gl/maplibre';
import { Link } from 'react-router-dom';

import {useState} from 'react';
// import Map, {Popup} from 'react-map-gl/maplibre';

const Home = () => {
    const [showPopup, setShowPopup] = useState(true);


    return (
        <main>
            <section id="hero">
                {/* Big map here */}
                <br/><br/><br/>
          
          
                <Map
    initialViewState={{
        longitude: -122.4,
        latitude: 37.8,
        zoom: 15
        
    }}
    style={{height: 400}}
    mapStyle="https://api.maptiler.com/maps/streets/style.json?key=7kAP6qpSdiHsUCJyW9zC"
  >
        <Marker longitude={-122.3989} latitude={37.7989} anchor="bottom" >

<img src="./Store.png" width={30} height={30} />

</Marker>

<Marker longitude={-122.4} latitude={37.8} anchor="bottom" >

<img src="./location.png" width={30} height={30} />

</Marker>

    {/* {showPopup && (
      <Popup longitude={-122.4} latitude={37.8}
        anchor="bottom"
        // onClose={() => setShowPopup(false)}
        >
        You are here
      </Popup>)} */}

 
  </Map>

                {/* <Map
      initialViewState={{
        longitude: -122.4,
        latitude: 37.8,
        zoom: 15
      }}
      style={{width: 1330, height: 400}}
      mapStyle="https://api.maptiler.com/maps/streets/style.json?key=7kAP6qpSdiHsUCJyW9zC"
    >
        

         <Marker longitude={-122.4} latitude={37.8} anchor="bottom" >

     <img src="./location.png" width={50} height={50} />

   </Marker>

   

        <Marker longitude={-122.4} latitude={37.7888}  >

<img src="./Store.png" />

</Marker>    

     <Marker longitude={-120} latitude={36} anchor="bottom" >

     <img src="./Store.png" width={50} height={50} />

</Marker>

<Marker longitude={-122} latitude={40} anchor="bottom" >

<img src="./Store.png" width={50} height={50} />

</Marker>


    </Map> */}


{/* <Map
    mapboxAccessToken="pk.eyJ1IjoiZ29kc3dpbGwtbmF0aGFuIiwiYSI6ImNtMHU3OXk0dDAzZnYya3M1OW1ybHI4M24ifQ.dQGIlS-j2osnaxsRwdFrUA"
    initialViewState={{
      longitude: 7.8783857,
      latitude: 8.8453848,
      // zoom: 15
      zoom: 14
    }}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    style={{width: 1300, height: 400}}
  >
    <Marker longitude={7.8783857} latitude={8.8453848} anchor="bottom" >
      <img src="./Post logo.png" width={100} height={100} />
    </Marker>
  </Map> */}
                {/* Big map here end */}
                
                {/* <HeroSlider /> */}
            </section>

            <section id="featured" className="section">
                <div className="container">
                    <SectionsHead heading="Featured Products" />
                    <FeaturedSlider />
                </div>
            </section>

            <section id="products" className="section">
                <div className="container">
                    <SectionsHead heading="Top Products" />
                    <TopProducts />
                </div>
            </section>

            {/* <Services /> */}
            <Link to={`/store`}>Go to StoreFront</Link>

        </main> 
    );
};

export default Home;;