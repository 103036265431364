import React, { useState, useEffect } from 'react';
import QRCodeGenerator from '../components/common/QRCodeGenerator';
import CartItem_store from '../components/cart/CartItem_store';
import '../styles/popup.css';

import { collection, addDoc, onSnapshot, getFirestore, getDoc } from 'firebase/firestore';

// firebase.js
import { initializeApp } from 'firebase/app';

// Your Firebase config object from the Firebase Console
const firebaseConfig = {
   apiKey: "AIzaSyBvEeweHv6fee4qWwrg1ASsAsfYfEoMXZE",
  authDomain: "cartpost-web.firebaseapp.com",
  databaseURL: "https://cartpost-web-default-rtdb.firebaseio.com",
  projectId: "cartpost-web",
  storageBucket: "cartpost-web.appspot.com",
  messagingSenderId: "936600138275",
  appId: "1:936600138275:web:d1c09731741adb7f12b796",
  measurementId: "G-S7RE9RN74G"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);



const StoreOrder = () => {
  const [orders, setOrders] = useState([]); // State to store orders from Firestore
  const [isOpen, setIsOpen] = useState(false);
  const [verifiedOrder, setVerifiedOrder] = useState(null); // To keep track of the latest verified order
  const [verificationStatus, setVerificationStatus] = useState(null); // To store verification status

  useEffect(() => {
    // Firestore real-time listener for the 'store_orders' collection
    const unsubscribeOrders = onSnapshot(collection(db, 'store_orders'), (snapshot) => {
      const ordersData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setOrders(ordersData); // Update the state with Firestore data
    });

    // Firestore real-time listener for the 'verification' collection
    const unsubscribeVerification = onSnapshot(collection(db, 'fication'), async (snapshot) => {
      // Assuming there is only one verification document to check
      if (!snapshot.empty) {
        const verificationDoc = snapshot.docs[0]; // Grab the first document
        const { verified } = verificationDoc.data(); // Get the boolean value of 'verified'
        setVerificationStatus(verified); // Update the verification status
        if (verified) {
          // Assuming the `verify` status means showing the popup for verification
          const latestOrder = await getLatestVerifiedOrder(); // Get the latest verified order
          setVerifiedOrder(latestOrder);
          openPopup();
        }
      }
    });

    // Clean up the listeners on unmount
    return () => {
      unsubscribeOrders();
      unsubscribeVerification();
    };
  }, []);

  const getLatestVerifiedOrder = async () => {
    const orderRef = collection(db, 'store_orders');
    const snapshot = await getDoc(orderRef);
    const ordersData = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return ordersData.find(order => order.status === 'verify');
  };

  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);



  return (
    <>


      <section id="cart" className="section">
        <div className="container">
          <div className="wrapper cart_wrapper">
            <div className="cart_right_col">
              {/* Displaying the orders */}
              {orders.length > 0 ? (
                orders.map(item => (
                  <CartItem_store key={item.id} {...item} />
                ))
              ) : (
                <span>No Placed Orders</span>
              )}
            </div>

            <div className="cart_left_col">
              <div className="order_summary">
                <h3>Scan to verify</h3>
                <div className="order_summary_details">
                  <QRCodeGenerator />

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StoreOrder;




// import React, { useContext, useState, useEffect } from 'react';
// import { collection, onSnapshot } from 'firebase/firestore';
// import QRCodeGenerator from '../components/common/QRCodeGenerator';
// import CartItem_store from '../components/cart/CartItem_store';
// import '../styles/popup.css';


// import { collection, addDoc, onSnapshot, getFirestore } from 'firebase/firestore';

// // firebase.js
// import { initializeApp } from 'firebase/app';

// // Your Firebase config object from the Firebase Console
// const firebaseConfig = {
//    apiKey: "AIzaSyBvEeweHv6fee4qWwrg1ASsAsfYfEoMXZE",
//   authDomain: "cartpost-web.firebaseapp.com",
//   databaseURL: "https://cartpost-web-default-rtdb.firebaseio.com",
//   projectId: "cartpost-web",
//   storageBucket: "cartpost-web.appspot.com",
//   messagingSenderId: "936600138275",
//   appId: "1:936600138275:web:d1c09731741adb7f12b796",
//   measurementId: "G-S7RE9RN74G"
// };

// // Initialize Firebase and Firestore
// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);



// const StoreOrder = () => {
//   const [orders, setOrders] = useState([]); // State to store orders from Firestore
//   const [isOpen, setIsOpen] = useState(false);

//   useEffect(() => {
//     // Firestore real-time listener for the 'orders' collection
//     const unsubscribe = onSnapshot(collection(db, 'orders'), (snapshot) => {
//       const ordersData = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));

//       // Check if the verification is complete and show the popup
//       const latestOrder = ordersData.find(order => order.status === 'verify');
//       if (latestOrder) {
//         openPopup();
//       }


//               if (form == "verify") {
//             openPopup();
//         } else if (form == "push") {
//             let int_item = jk.msg;

//             handleArrBool(true);

           
//         }

//       setOrders(ordersData); // Update the state with Firestore data
//     });

//     // Clean up the listener on unmount
//     return () => unsubscribe();
//   }, []);

//   const openPopup = () => setIsOpen(true);
//   const closePopup = () => setIsOpen(false);

//   return (
//     <>
//       {isOpen && (
//         <div className="popup-overlay">
//           <div className="popup-content">
//             <div className="popup-header">
//               <img src="./done icon.png" alt="done icon" style={{ width: '100%', height: 'auto', marginTop: '10px' }} />
//               <button className="popup-close" onClick={closePopup}>✖</button>
//             </div>
//             <div className="popup-body">
//               <h1>Order: 235</h1>
//               <p>Verification complete!</p>
//               <p>Customer: Pep</p>
//             </div>
//           </div>
//         </div>
//       )}

//       <section id="cart" className="section">
//         <div className="container">
//           <div className="wrapper cart_wrapper">
//             <div className="cart_right_col">
//               {orders.length > 0 ? (
//                 orders.map(item => (
//                   <CartItem_store key={item.id} {...item} />
//                 ))
//               ) : (
//                 <span>No Placed order</span>
//               )}
//             </div>

//             <div className="cart_left_col">
//               <div className="order_summary">
//                 <h3>Scan to verify</h3>
//                 <div className="order_summary_details">
//                   <QRCodeGenerator />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default StoreOrder;




// import React, { useContext, useState } from 'react';
// import { BsCartX } from 'react-icons/bs';
// import { calculateTotal, displayMoney } from '../helpers/utils';
// import useDocTitle from '../hooks/useDocTitle';
// // import OrderContext from '../contexts/cart/OrderContext';
// import cartContext from '../contexts/cart/cartContext';
// // import CartItem_store from '../components/cart/CartItem_store';
// import EmptyView from '../components/common/EmptyView';

// import filtersContext from '../contexts/filters/filtersContext';
// import useActive from '../hooks/useActive';
// import productsData from '../data/productsData';

// import QRCodeGenerator from '../components/common/QRCodeGenerator';

// import CartItem_store from '../components/cart/CartItem_store';


// import { Link } from 'react-router-dom';


// import '../styles/popup.css'; // Include your CSS for styling



// const StoreOrder = () => {

//     useDocTitle('Orders');

//     const { cartItems } = useContext(cartContext);

//     const { allProducts } = useContext(filtersContext);


//     const cartQuantity = cartItems.length;

//     // total original price
//     const cartTotal = cartItems.map(item => {
//         return item.originalPrice * item.quantity;
//     });

//     const calculateCartTotal = calculateTotal(cartTotal);
//     const displayCartTotal = displayMoney(calculateCartTotal);


//     // total discount
//     const cartDiscount = cartItems.map(item => {
//         return (item.originalPrice - item.finalPrice) * item.quantity;
//     });

//     const calculateCartDiscount = calculateTotal(cartDiscount);
//     const displayCartDiscount = displayMoney(calculateCartDiscount);


//     // final total amount
//     const totalAmount = calculateCartTotal - calculateCartDiscount;
//     const displayTotalAmount = displayMoney(totalAmount);

    
//     let ws;
//     let isWsOpen = false;

//     const [arrBool, handleArrBool] = useState(false);

//     let arr = [
//       {
//         id: 1,
//         tag: "hero-product",
//         tagline: "Keep the noise out, or in. You choose.",
//         heroImage: "/images/products/jbl660nc-1.png",
//         images: [
//             "/images/products/jbl660nc-1.png",
//             "/images/products/jbl660nc-2.png",
//             "/images/products/jbl660nc-3.png",
//             "/images/products/jbl660nc-4.png",
//         ],
//         brand: "JBL",
//         title: "JBL Live 660NC",
//         info: "Wireless Over-Ear NC Headphones",
//         category: "Headphones",
//         type: "Over Ear",
//         connectivity: "Wireless",
//         finalPrice: 9999,
//         originalPrice: 14999,
//         quantity: 1,
//         ratings: 1234,
//         rateCount: 5,
//         path: "/product-details/",
//     }

//     ]
//     const QUEUE_PREFIX = 'audio_channel_1';
//     const { active, handleActive, activeClass } = useActive(false);
//     const { dialogBoxactive, handleDialogBoxactive } = useActive(false);


//     function first() {
//       console.log('Connecting to ws');
//     //   ws = new WebSocket('ws://localhost:5000');
//     ws = new WebSocket('ws://localhost:8080');
//       // ws.binaryType = 'arraybuffer';
//       ws.onopen = () => {
//         isWsOpen = true;
//         console.log('WebSocket connection opened.');
//         // ws.send(JSON.stringify({ action: 'publish', channel: QUEUE_PREFIX, data: document.getElementById("Data").value }));
//         subscribe();
//       };
      

//       ws.onclose = () => {
//         isWsOpen = false;
//         console.log('WebSocket connection closed');
//       };

//       ws.onerror = (error) => {
//         isWsOpen = false;
//         console.error('WebSocket error:', error);
//       };

//     }



//     const [isOpen, setIsOpen] = useState(false);

//     const openPopup = () => setIsOpen(true);
//     const closePopup = () => setIsOpen(false);
  

  


//     function subscribe(channel) {
//       console.log('Subscribing to channel');
  
//         console.log('WebSocket connection opened for subscribing');
//         ws.send(JSON.stringify({ action: 'subscribe', channel: QUEUE_PREFIX }));
     
//       ws.onmessage = (event) => {
//         console.log('Received audio data chunk:', event.data);
//         console.log('Received:', JSON.parse(event.data).data);
//         let jk = JSON.parse(event.data);
//         let form = jk.form;
       
//         if (form == "verify") {
//             openPopup();
//         } else if (form == "push") {
//             let int_item = jk.msg;

//             handleArrBool(true);

//             // handleArr.push(productsData[1])
//             // arr.push(productsData[1])
//             // productsData.forEach(element => {
//             //     if (element.id == ) {
//             //         arr.push(element)
//             //     }
//             // });
//         }
        
//       };

//     }

//     function opp() {
//        first();
       
    
//     }

   




//     return (
//         <>
//         {isOpen && (
//         <div className="popup-overlay">
//           <div className="popup-content">
//             <div className="popup-header">
//               {/* <h3>Popup Dialog</h3> */}
//               <img
//                 src="./done icon.png" 
//                 alt="done icon" 
//                 style={{ width: '100%', height: 'auto', marginTop: '10px' }} 
//               />
//               <button className="popup-close" onClick={closePopup}>✖</button>
//             </div>
//             <div className="popup-body">
//               <h1>Order: 235</h1>
//               <p>Verification complete!</p>
//               <p>Customer: Pep</p>
//             </div>
//           </div>
//         </div>
//       )}

//             <section id="cart" className="section">
//                 <div className="container">
                   
//                             <div className="wrapper cart_wrapper">
//                                 {/* <div className="cart_left_col"> */}
//                                 <div className="cart_right_col">
                           
//                            {
                          
                          
                                      
//                             // arr.length > 0 ?
//                             arrBool ? 
//                              arr.map(item => (
//                               <CartItem_store
//                                   key={item.id}
//                                   {...item}
//                               />

//                           )) 
                            
//                             : 
                              
//                             <span>No Placed order</span>
                             
                          

//                           //  setTimeout(()=>{

//                         //    arr.map(item => (
//                         //     <CartItem_store
//                         //     key={item.id}
//                         //     {...item}
//                         // />
//                         //                 ))

// //   }, 5000
// // ) 
                         

//                            }

//                                 {/* {
                                      
//                                       arr.length > 0 ? ()=>{
//                                       console.log("lll");
//                                        arr.map(item => (
//                                         <CartItem_store
//                                             key={item.id}
//                                             {...item}
//                                         />

//                                     )) 
//                                     }
                                      
//                                       : 
                                        
//                                       <span>No Placed order</span>
                                        
//                                         } */}
                                      
                    
//                                     {/* { 
                                    
//                                         // For now, since they share the same cart system, 
//                                         // cartItems.map(item => (
//                                         //     <CartItem_store
//                                         //         key={item.id}
//                                         //         {...item}
//                                         //     />
//                                         // ))

//                                         arr.map(item => (
//                                             <CartItem_store
//                                                 key={item.id}
//                                                 {...item}
//                                             />
//                                         ))
//                                     } */}
//                                 </div>

//                                 {/* <div className="cart_right_col"> */}
//                                 <div className="cart_left_col">
//                                     <div className="order_summary">
//                                         <h3>
//                                             Scan to verify &nbsp;
//                                             {/* ( {cartQuantity} {cartQuantity > 1 ? 'items' : 'item'} ) */}
//                                         </h3>
//                                         <div className="order_summary_details">
                                     
//                                         {/* <button onClick={openPopup}>Open Popup</button> */}

//                                         {/* qrcode here */}

//                                         <QRCodeGenerator/>

//   <hr/>
//   <span onClick={opp}>Connect</span>
 
//   {/* <hr/>
//   <span onClick={()=>{
//   // cartItems.push(productsData[1]);
//     handleArrBool(true);
//   console.log(arr)
//   }}>Fake it</span>

//   <Link to={`/orders`}>/orders</Link> */}


//                                         {/* qrcode here end */}


//                                             {/* <div className="price">
//                                                 <span>Original Price</span>
//                                                 <b>{displayCartTotal}</b>
//                                             </div>
//                                             <div className="discount">
//                                                 <span>Discount</span>
//                                                 <b>- {displayCartDiscount}</b>
//                                             </div>
//                                             <div className="delivery">
//                                                 <span>Delivery</span>
//                                                 <b>Free</b>
//                                             </div>
//                                             <div className="separator"></div>
//                                             <div className="total_price">
//                                                 <b><small>Total Price</small></b>
//                                                 <b>{displayTotalAmount}</b>
//                                             </div> */}


//                                         </div>
                                     
//                                         {/* <button type="button" className="btn checkout_btn">Checkout</button> */}
                                    
//                                     </div>
//                                 </div>
//                             </div>
                    
//                 </div>
//             </section>
//         </>
//     );
// };

// export default StoreOrder;