import React, {  useState } from 'react';
import {QRCodeSVG} from 'qrcode.react';
import '../../styles/popup.css';


const QRCodeGenerator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);


  function start_som() {
    setTimeout(()=>{
      openPopup();
    }, 6000)
    
  };

  start_som();

  let op = JSON.stringify({
    "store": "hl7464746478",
    "ss": "opopoppp"
  })

  // const dataToEncode = "store:7464746478,ss:opopppp";
  const dataToEncode = "https://graceofbind.web.app/Team.html";

  return (
<div >
{/* <QRCodeSVG value={dataToEncode} size={300} marginSize={3} /> */}

{isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <img
                src="./done icon.png"
                alt="done icon"
                style={{ width: '100%', height: 'auto', marginTop: '10px' }}
              />
              <button className="popup-close" onClick={closePopup}>✖</button>
            </div>
            <div className="popup-body">
              <h1>Order: 235</h1>
              <p>Verification complete!</p>
              <p>Customer: Pep</p>
            </div>
          </div>
        </div>
      )}

      {/* Popup to show the latest verified order
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <img src="./done icon.png" alt="done icon" style={{ width: '100%', height: 'auto', marginTop: '10px' }} />
              <button className="popup-close" onClick={closePopup}>✖</button>
            </div>
            <div className="popup-body">
              <h1>Order: {verifiedOrder.id}</h1>
              <p>{verificationStatus ? 'Verification complete!' : 'Verification failed.'}</p>
              <p>Customer: {verifiedOrder.customerName || 'Unknown'}</p>
            </div>
          </div>
        </div>
      )} */}

<QRCodeSVG value={op} size={300} marginSize={3} />
</div>
  );
};

export default QRCodeGenerator;
