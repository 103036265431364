import React, { useContext } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import useDocTitle from '../hooks/useDocTitle';
import FilterBar from '../components/filters/FilterBar';
import ProductCard from '../components/product/ProductCard';
import Services from '../components/common/Services';
import filtersContext from '../contexts/filters/filtersContext';
import EmptyView from '../components/common/EmptyView';

import { Link } from 'react-router-dom';
import ProductCard_store from '../components/product/ProductCard_store';


const StoreFront = () => {

    useDocTitle('Store inventory');

    const { allProducts } = useContext(filtersContext);

    let ws;
    let isWsOpen = false;
    const QUEUE_PREFIX = 'audio_channel_1';


    function first() {
      console.log('Connecting to ws');
      ws = new WebSocket('ws://localhost:8080');
      // ws.binaryType = 'arraybuffer';
      ws.onopen = () => {
        isWsOpen = true;
        console.log('WebSocket connection opened.');
        // ws.send(JSON.stringify({ action: 'publish', channel: QUEUE_PREFIX, data: document.getElementById("Data").value }));
       
      };
      

      ws.onclose = () => {
        isWsOpen = false;
        console.log('WebSocket connection closed');
      };

      ws.onerror = (error) => {
        isWsOpen = false;
        console.error('WebSocket error:', error);
      };

    }


    function subscribe(channel) {
      console.log('Subscribing to channel', channel);
  
        console.log('WebSocket connection opened for subscribing');
        ws.send(JSON.stringify({ action: 'subscribe', channel: QUEUE_PREFIX }));
     
      ws.onmessage = (event) => {
        console.log('Received audio data chunk:', event.data);
        console.log('Received:', JSON.parse(event.data).data);
        
      };

    }

    // first();
    // subscribe();


    return (
        <>
            <section id="all_products" className="section">
                <FilterBar />

                <div className="container">

                <Link to={`/orders`}>Go to Orders</Link>
        <hr />
                    {
                        allProducts.length ? (
                            <div className="wrapper products_wrapper">
                                {
                                    allProducts.map(item => (
                                        <ProductCard_store
                                            key={item.id}
                                            {...item}
                                        />
                                    ))
                                }
                            </div>
                        ) : (
                            <EmptyView
                                icon={<BsExclamationCircle />}
                                msg="No Results Found"
                            />
                        )
                    }
                </div>
            </section>

            {/* <Services /> */}
        </>
    );
};

export default StoreFront;