import React, { useContext, useState } from 'react';
import { BsCartX } from 'react-icons/bs';
import { calculateTotal, displayMoney } from '../helpers/utils';
import useDocTitle from '../hooks/useDocTitle';
import cartContext from '../contexts/cart/cartContext';
import CartItem from '../components/cart/CartItem';
import EmptyView from '../components/common/EmptyView';
// import { QrReader } from 'react-qr-reader';
import { Scanner } from '@yudiel/react-qr-scanner';

import '../styles/popup.css'; // Include your CSS for styling


import { collection, addDoc, onSnapshot, getFirestore } from 'firebase/firestore';

// firebase.js
import { initializeApp } from 'firebase/app';

// Your Firebase config object from the Firebase Console
const firebaseConfig = {
   apiKey: "AIzaSyBvEeweHv6fee4qWwrg1ASsAsfYfEoMXZE",
  authDomain: "cartpost-web.firebaseapp.com",
  databaseURL: "https://cartpost-web-default-rtdb.firebaseio.com",
  projectId: "cartpost-web",
  storageBucket: "cartpost-web.appspot.com",
  messagingSenderId: "936600138275",
  appId: "1:936600138275:web:d1c09731741adb7f12b796",
  measurementId: "G-S7RE9RN74G"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);



const Cart = () => {
  useDocTitle('Cart');
  const { cartItems } = useContext(cartContext);
  const [pickup, handlePickup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [scannedData, setScannedData] = useState(null);

  const cartQuantity = cartItems.length;

  // total original price
  const cartTotal = cartItems.map(item => item.originalPrice * item.quantity);
  const calculateCartTotal = calculateTotal(cartTotal);
  const displayCartTotal = displayMoney(calculateCartTotal);

  // total discount
  const cartDiscount = cartItems.map(
    item => (item.originalPrice - item.finalPrice) * item.quantity
  );
  const calculateCartDiscount = calculateTotal(cartDiscount);
  const displayCartDiscount = displayMoney(calculateCartDiscount);

  // final total amount
  const totalAmount = calculateCartTotal - calculateCartDiscount;
  const displayTotalAmount = displayMoney(totalAmount);

  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);

  const Pickup_start = () => handlePickup(true);

      async function Checkout() {

       cartItems.map((item) => {

        try {
          addDoc(collection(db, 'store_orders'), item);
          alert('Checkout Successful');
        } catch (error) {
          console.error('Error adding document: ', error);
        }

      
          
      })
        

       
    }

  const handleScan = async (data) => {
    // if (data) {
      // setScannedData(data);
      alert("Done");

      try {
        addDoc(collection(db, 'verification'), { verified: true });
        // alert('Checkout Successful');
      } catch (error) {
        console.error('Error adding document: ', error);
      }

    
      // // Here, you will update the verification document in Firestore
      // try {
      //   await addDoc(collection(db, 'verification', 'verify'), { verified: true });
        
      //   console.log('Verification status set to true');
      //   openPopup(); // Show success popup
      // } catch (error) {
      //   console.error('Error updating verification:', error);
      // }
    // }
  };

  const handleError = (err) => {
    console.error(err);
  };

  function start_som() {
    setTimeout(()=>{
      openPopup();
    }, 6000)
    
  };

  start_som();

  return (
    <>
  

      <section id="cart" className="section">
        <div className="container">
          {cartQuantity === 0 ? (
            <EmptyView
              icon={<BsCartX />}
              msg="Your Cart is Empty"
              link="/all-products"
              btnText="Start Shopping"
            />
          ) : (
            <div className="wrapper cart_wrapper">
              <div className="cart_left_col">
                {cartItems.map((item) => (
                  <CartItem key={item.id} {...item} />
                ))}
               
              </div>

              <div className="cart_right_col">

              {isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <img
                src="./done icon.png"
                alt="done icon"
                style={{ width: '100%', height: 'auto', marginTop: '10px' }}
              />
              <button className="popup-close" onClick={closePopup}>✖</button>
            </div>
            <div className="popup-body">
              <h1>Order: 235</h1>
              <p>Verification complete!</p>
              <p>Customer: Pep</p>
            </div>
          </div>
        </div>
      )}


                <div className="order_summary">
                  <h3>
                    Order Summary &nbsp;( {cartQuantity}{' '}
                    {cartQuantity > 1 ? 'items' : 'item'} )
                  </h3>
                  <div className="order_summary_details">
                    <div className="price">
                      <span>Original Price</span>
                      <b>{displayCartTotal}</b>
                    </div>
                    <div className="discount">
                      <span>Discount</span>
                      <b>- {displayCartDiscount}</b>
                    </div>
                    <div className="delivery">
                      <span>Delivery</span>
                      <b>Free</b>
                    </div>
                    <div className="separator"></div>
                    <div className="total_price">
                      <b><small>Total Price</small></b>
                      <b>{displayTotalAmount}</b>
                    </div>
                  </div>
                  <button type="button" className="btn checkout_btn" onClick={Checkout}>Checkout</button>
                                                     
                  <br />
                  <hr />
                  <br />
                  <button
                    type="button"
                    className="btn checkout_btn"
                    onClick={Pickup_start}
                  >
                    Pick up / Verify
                  </button>
                  {pickup ? (
<>
<Scanner onScan={(result) => {
  {/* if (result) { */}
  {/* alert("Done"); */}

  // alert("Done");

  // addDoc(collection(db, 'fication'), { verified: true });
  // // alert('Checkout Successful');
console.log("Done");

    


  {/* } */}
}}

style={{ width: '300px' }}

/>
<br/>
</>
             
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Cart;



// import React, { useContext, useState } from 'react';
// import { BsCartX } from 'react-icons/bs';
// import { calculateTotal, displayMoney } from '../helpers/utils';
// import useDocTitle from '../hooks/useDocTitle';
// import cartContext from '../contexts/cart/cartContext';
// import CartItem from '../components/cart/CartItem';
// import EmptyView from '../components/common/EmptyView';

// import useActive from '../hooks/useActive';
// import { db } from '../firebase'; // Assuming you have Firebase configured here

// import { QrReader } from 'react-qr-reader';

// const Cart = () => {
//     useDocTitle('Cart');

//     const { cartItems } = useContext(cartContext);

//     const [dialogBoxactive, handleDialogBoxactive] = useState(false);
//     const [pickup, handlePickup] = useState(false);
//     const [isOpen, setIsOpen] = useState(false);
//     const [scannedData, setScannedData] = useState(null);

//     const cartQuantity = cartItems.length;

//     // total original price
//     const cartTotal = cartItems.map(item => item.originalPrice * item.quantity);
//     const calculateCartTotal = calculateTotal(cartTotal);
//     const displayCartTotal = displayMoney(calculateCartTotal);

//     // total discount
//     const cartDiscount = cartItems.map(item => (item.originalPrice - item.finalPrice) * item.quantity);
//     const calculateCartDiscount = calculateTotal(cartDiscount);
//     const displayCartDiscount = displayMoney(calculateCartDiscount);

//     // final total amount
//     const totalAmount = calculateCartTotal - calculateCartDiscount;
//     const displayTotalAmount = displayMoney(totalAmount);

//     // Firestore Checkout function
//     async function Checkout() {
//         const payload = { msg: 1 };

//         try {
//             await db.collection('orders').add(payload); // 'orders' collection in Firestore
//             alert('Checkout Successful');
//         } catch (error) {
//             console.error('Error adding document: ', error);
//         }
//     }

//     // Firestore Pickup function
//     async function Pickup() {
//         const payload = { msg: 1 };

//         try {
//             await db.collection('pickup_orders').add(payload); // 'pickup_orders' collection in Firestore
//             openPopup();
//         } catch (error) {
//             console.error('Error adding document: ', error);
//         }
//     }

//     const openPopup = () => setIsOpen(true);
//     const closePopup = () => setIsOpen(false);

//     const Pickup_start = () => handlePickup(true);

//     const handleScan = (data) => {
//         if (data) {
//             Pickup(); // Trigger Pickup when scanned
//             setScannedData(data);
//         }
//     };

//     const handleError = (err) => {
//         console.error(err);
//     };

//     return (
//         <>
//             {isOpen && (
//                 <div className="popup-overlay">
//                     <div className="popup-content">
//                         <div className="popup-header">
//                             <img src="./done icon.png" alt="done icon" style={{ width: '100%', height: 'auto', marginTop: '10px' }} />
//                             <button className="popup-close" onClick={closePopup}>✖</button>
//                         </div>
//                         <div className="popup-body">
//                             <h1>Order: 235</h1>
//                             <p>Verification complete!</p>
//                             <p>Customer: Pep</p>
//                         </div>
//                     </div>
//                 </div>
//             )}

//             <section id="cart" className="section">
//                 <div className="container">
//                     {cartQuantity === 0 ? (
//                         <EmptyView icon={<BsCartX />} msg="Your Cart is Empty" link="/all-products" btnText="Start Shopping" />
//                     ) : (
//                         <div className="wrapper cart_wrapper">
//                             <div className="cart_left_col">
//                                 {cartItems.map((item) => (
//                                     <CartItem key={item.id} {...item} />
//                                 ))}
//                             </div>

//                             <div className="cart_right_col">
//                                 <div className="order_summary">
//                                     <h3>Order Summary &nbsp;( {cartQuantity} {cartQuantity > 1 ? 'items' : 'item'} )</h3>
//                                     <div className="order_summary_details">
//                                         <div className="price">
//                                             <span>Original Price</span>
//                                             <b>{displayCartTotal}</b>
//                                         </div>
//                                         <div className="discount">
//                                             <span>Discount</span>
//                                             <b>- {displayCartDiscount}</b>
//                                         </div>
//                                         <div className="delivery">
//                                             <span>Delivery</span>
//                                             <b>Free</b>
//                                         </div>
//                                         <div className="separator"></div>
//                                         <div className="total_price">
//                                             <b><small>Total Price</small></b>
//                                             <b>{displayTotalAmount}</b>
//                                         </div>
//                                     </div>
//                                     <button type="button" className="btn checkout_btn" onClick={Checkout}>Checkout</button>
//                                     <br /><hr /><br />
//                                     <button type="button" className="btn checkout_btn" onClick={Pickup_start}>Pick up / Verify</button>
//                                     {pickup && (
//                                         <QrReader
//                                             onResult={(result, error) => {
//                                                 if (result) {
//                                                     handleScan(result.text);
//                                                 }
//                                                 if (error) {
//                                                     handleError(error);
//                                                 }
//                                             }}
//                                             style={{ width: '300px' }}
//                                         />
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             </section>
//         </>
//     );
// };

// export default Cart;




// import React, { useContext, useState } from 'react';
// import { BsCartX } from 'react-icons/bs';
// import { calculateTotal, displayMoney } from '../helpers/utils';
// import useDocTitle from '../hooks/useDocTitle';
// import cartContext from '../contexts/cart/cartContext';
// import CartItem from '../components/cart/CartItem';
// import EmptyView from '../components/common/EmptyView';

// import useActive from '../hooks/useActive';

// import '../styles/popup.css'; // Include your CSS for styling


// import { QrReader } from 'react-qr-reader';



// const Cart = () => {

//     useDocTitle('Cart');

//     const { cartItems } = useContext(cartContext);

//     const { dialogBoxactive, handleDialogBoxactive } = useState(false);
    


//     const cartQuantity = cartItems.length;

//     // total original price
//     const cartTotal = cartItems.map(item => {
//         return item.originalPrice * item.quantity;
//     });

//     const calculateCartTotal = calculateTotal(cartTotal);
//     const displayCartTotal = displayMoney(calculateCartTotal);


//     // total discount
//     const cartDiscount = cartItems.map(item => {
//         return (item.originalPrice - item.finalPrice) * item.quantity;
//     });

//     const calculateCartDiscount = calculateTotal(cartDiscount);
//     const displayCartDiscount = displayMoney(calculateCartDiscount);


//     // final total amount
//     const totalAmount = calculateCartTotal - calculateCartDiscount;
//     const displayTotalAmount = displayMoney(totalAmount);

//     function Checkout() {
//         const payload = {
//             msg: 1,
//               }

//      fetch("http://192.168.43.206:7000/push_order", {
//          method: "POST",
//          headers: {
//              "Accept": "application/json",
//              "Content-Type": "application/json"
//          },
//          body: JSON.stringify(payload)
//      }).then(k=>{

        
//          k.json().then(k=>{

//          if (k.msg == "Successful") {
//             alert("Successful")
//          }

//      })

//      })
//      .catch(k=>{
//          console.log(k)
//      })

//  }

//  function Pickup() {
//     const payload = {
//         msg: 1,
//           }

//  fetch("http://192.168.43.206:7000/verify_order", {
//      method: "POST",
//      headers: {
//          "Accept": "application/json",
//          "Content-Type": "application/json"
//      },
//      body: JSON.stringify(payload)
//  }).then(k=>{

    
//      k.json().then(k=>{

//      if (k.msg == "Successful") {
        
//         openPopup();

//      }

//  })

//  })
//  .catch(k=>{
//      console.log(k)
//  })

// }

// // const { pickup, handlePickup } = useActive(false);
// const [pickup, handlePickup] = useState(false);

// const [isOpen, setIsOpen] = useState(false);

// const openPopup = () => setIsOpen(true);
// const closePopup = () => setIsOpen(false);


// function Pickup_start() {
//     handlePickup(true);
// }

// const [scannedData, setScannedData] = useState(null);

// const handleScan = (data) => {
//   if (data) {
//     // 
    
//     Pickup();
//     // 
//     setScannedData(data);
//   }
// };

// const handleError = (err) => {
//   console.error(err);
// };


//     return (
//         <>
//            {isOpen && (
//         <div className="popup-overlay">
//           <div className="popup-content">
//             <div className="popup-header">
//               {/* <h3>Popup Dialog</h3> */}
//               <img
//                 src="./done icon.png" 
//                 alt="done icon" 
//                 style={{ width: '100%', height: 'auto', marginTop: '10px' }} 
//               />
//               <button className="popup-close" onClick={closePopup}>✖</button>
//             </div>
//             <div className="popup-body">
//               <h1>Order: 235</h1>
//               <p>Verification complete!</p>
//               <p>Customer: Pep</p>
//             </div>
//           </div>
//         </div>
//       )}

//             <section id="cart" className="section">
//                 <div className="container">
//                     {
//                         cartQuantity === 0 ? (
//                             <EmptyView
//                                 icon={<BsCartX />}
//                                 msg="Your Cart is Empty"
//                                 link="/all-products"
//                                 btnText="Start Shopping"
//                             />
//                         ) : (
//                             <div className="wrapper cart_wrapper">
//                                 <div className="cart_left_col">
//                                     {
//                                         cartItems.map(item => (
//                                             <CartItem
//                                                 key={item.id}
//                                                 {...item}
//                                             />
//                                         ))
//                                     }
//                                 </div>

//                                 <div className="cart_right_col">
//                                     <div className="order_summary">
//                                         <h3>
//                                             Order Summary &nbsp;
//                                             ( {cartQuantity} {cartQuantity > 1 ? 'items' : 'item'} )
//                                         </h3>
//                                         <div className="order_summary_details">
//                                             <div className="price">
//                                                 <span>Original Price</span>
//                                                 <b>{displayCartTotal}</b>
//                                             </div>
//                                             <div className="discount">
//                                                 <span>Discount</span>
//                                                 <b>- {displayCartDiscount}</b>
//                                             </div>
//                                             <div className="delivery">
//                                                 <span>Delivery</span>
//                                                 <b>Free</b>
//                                             </div>
//                                             <div className="separator"></div>
//                                             <div className="total_price">
//                                                 <b><small>Total Price</small></b>
//                                                 <b>{displayTotalAmount}</b>
//                                             </div>
//                                         </div>
                                 
//                                         <button type="button" className="btn checkout_btn" onClick={() => Checkout}>Checkout</button>
//                                         <br/>
//                                     <hr/>
//                                     <br/>
//                                         <button type="button" className="btn checkout_btn" onClick={() => Pickup_start}>Pick up / Verify</button>
//                                         {
                                      
//     pickup ?  <QrReader
//         onResult={(result, error) => {
//           if (!!result) {
//             handleScan(result?.text);
//           }
//           if (!!error) {
//             handleError(error);
//           }
//         }}
//         style={{ width: '300px' }}
//       /> : <></>
      
//       }
    
                        
//                                     </div>
//                                 </div>
//                             </div>
//                         )
//                     }
//                 </div>
//             </section>
//         </>
//     );
// };

// export default Cart;